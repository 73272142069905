import * as types from '../actionTypes';

const initialState = {
  faq: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FAQ_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_FAQ_SUCCESS:
      return {...state, loading: false, faq: action.faq, error: null};
    case types.FETCH_FAQ_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;