import * as types from '../actionTypes';

const initialState = {
  allEvents: [],
  allEventsLoading: false,
  singleEvent: [],
  singleEventLoading: false,
  userEvents: [],
  userEventsLoading: false,
  loading: false,
  error: null,
  joinError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_EVENTS_REQUEST:
      return {...state, allEventsLoading: true, error: null};
    case types.FETCH_ALL_EVENTS_SUCCESS:
      return {...state, allEventsLoading: false, allEvents: action.allEvents, error: null};
    case types.FETCH_ALL_EVENTS_FAILURE:
      return {...state, allEventsLoading: false, error: action.error};

    case types.FETCH_SINGLE_EVENT_REQUEST:
      return {...state, singleEventLoading: true, error: null};
    case types.FETCH_SINGLE_EVENT_SUCCESS:
      return {...state, singleEventLoading: false, singleEvent: action.singleEvent, error: null};
    case types.FETCH_SINGLE_EVENT_FAILURE:
      return {...state, singleEventLoading: false, error: action.error};

    case types.JOIN_TO_EVENT_REQUEST:
      return {...state, loading: true, joinError: null};
    case types.JOIN_TO_EVENT_SUCCESS:
      return {...state, loading: false, joinError: null};
    case types.JOIN_TO_EVENT_FAILURE:
      return {...state, loading: false, joinError: action.error};

    case types.FETCH_USER_EVENTS_REQUEST:
      return {...state, userEventsLoading: true, error: null};
    case types.FETCH_USER_EVENTS_SUCCESS:
      return {...state, userEventsLoading: false, userEvents: action.userEvents, error: null};
    case types.FETCH_USER_EVENTS_FAILURE:
      return {...state, userEventsLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;