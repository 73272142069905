import * as types from '../actionTypes';

const initialState = {
  comments: [],
  commentsLoading: false,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_COMMENTS_REQUEST:
      return {...state, commentsLoading: true, error: null};
    case types.FETCH_COMMENTS_SUCCESS:
      return {...state, commentsLoading: false, comments: action.comments,  error: null};
    case types.FETCH_COMMENTS_FAILURE:
      return {...state, commentsLoading: false, error: action.error};

    case types.CREATE_COMMENTS_REQUEST:
      return {...state, loading: true, error: null};
    case types.CREATE_COMMENTS_SUCCESS:
      return {...state, loading: false, error: null};
    case types.CREATE_COMMENTS_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.PUT_COMMENTS_REQUEST:
      return {...state, loading: true, error: null};
    case types.PUT_COMMENTS_SUCCESS:
      return {...state, loading: false, error: null};
    case types.PUT_COMMENTS_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.DELETE_COMMENTS_REQUEST:
      return {...state, loading: true, error: null};
    case types.DELETE_COMMENTS_SUCCESS:
      return {...state, loading: false, error: null};
    case types.DELETE_COMMENTS_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;