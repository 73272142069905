import * as types from '../actionTypes';

const initialState = {
  documents: [],
  documentsLoading: false,
  documentTypes: [],
  userDocuments: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_DOCUMENTS_REQUEST:
      return {...state, documentsLoading: true, error: null};
    case types.FETCH_DOCUMENTS_SUCCESS:
      return {...state, documentsLoading: false, documents: action.documents, error: null};
    case types.FETCH_DOCUMENTS_FAILURE:
      return {...state, documentsLoading: false, error: action.error};

    case types.FETCH_DOCUMENT_TYPES_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_DOCUMENT_TYPES_SUCCESS:
      return {...state, loading: false, documentTypes: action.documentTypes, error: action.error};
    case types.FETCH_DOCUMENT_TYPES_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.CREATE_DOCUMENT_REQUEST:
      return {...state, loading: true, error: null};
    case types.CREATE_DOCUMENT_SUCCESS:
      return {...state, loading: false, userDocuments: action.userDocuments, error: null};
    case types.CREATE_DOCUMENT_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;