import * as types from '../actionTypes';

const initialState = {
  additionalMaterials: [],
  additionalMaterialsLoading: false,
  additionalMaterialsTypes: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_ADDITIONAL_MATERIALS_REQUEST:
      return {...state, additionalMaterialsLoading: true, error: null};
    case types.FETCH_ADDITIONAL_MATERIALS_SUCCESS:
      return {...state, additionalMaterialsLoading: false, additionalMaterials: action.additionalMaterials, error: null};
    case types.FETCH_ADDITIONAL_MATERIALS_FAILURE:
      return {...state, additionalMaterialsLoading: false, error: action.error};

    case types.FETCH_ADDITIONAL_MATERIAL_TYPES_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ADDITIONAL_MATERIAL_TYPES_SUCCESS:
      return {...state, loading: false, additionalMaterialsTypes: action.additionalMaterialsTypes, error: null};
    case types.FETCH_ADDITIONAL_MATERIAL_TYPES_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;