export const parseQuery = data => {
  return '?' + Object.keys(data).map(key => typeof data[key] === 'object' ?
    Object.keys(data[key]).map(k => key + '[' + k + ']=' + data[key][k]).join('&') :
    key + '=' + data[key]).join('&');
};

export const readQuery = queryString => {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};