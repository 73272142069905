import React, {Component} from 'react';
import {Switch, Route, BrowserRouter} from "react-router-dom";
import {connect} from "react-redux";
// import ReactGA from 'react-ga';
import {withTranslation} from 'react-i18next';
import {changeLanguage} from "./store/actions/auth";
// import {initGa, pageView} from "./index";


// import config from './config';
import {readQuery} from "./lib";
// import Register from "./containers/Register/Register";
// import {createBrowserHistory} from "history";


const Login = React.lazy(() => import('./containers/Login/Login'));
const Layout = React.lazy(() => import('./containers/Layout/Layout'));
// const FontAwesome = React.lazy(() => import('./pages/FontAwesome'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;



// const history = createBrowserHistory();
//
// history.listen(location => {
//   ReactGA.set({page: location.pathname});
//   ReactGA.pageview(location.pathname + location.search)
// });

class App extends Component {
  componentDidMount() {
    let queryLang = readQuery(this.props.history.location.search);
    if (!queryLang.lang) this.props.history.replace(this.props.history.location.pathname + `?lang=${this.props.language || 'kg'}`)
    this.props.onChangeLanguage(queryLang.lang ? queryLang.lang : this.props.language || 'kg');
    this.props.i18n.changeLanguage(queryLang.lang ? queryLang.lang : this.props.language || 'kg');
    // ReactGA.initialize(config.trackingId);

    // initGa(config.trackingId);
    // pageView();
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...this.props} {...props}/>}/>
            {/*<Route exact path="/register" name="Register Page" render={props => <Register {...this.props} {...props}/>} />*/}
            {/*<Route exact path="/font-awesome" name="Font awesome" render={props => <FontAwesome {...this.props} {...props}/>}/>*/}
            <Route path="/" name="Layout" render={props => <Layout {...this.props} {...props}/>}/>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  language: state.auth.language
});

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: lang => dispatch(changeLanguage(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
