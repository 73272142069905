import * as types from '../actionTypes';

const initialState = {
  trainings: [],
  trainingsLoading: false,
  oneTraining: [],
  oneTrainingLoading: false,
  userTrainings: [],
  loading: false,
  error: null,
  joinError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_TRAININGS_REQUEST:
      return {...state, trainingsLoading: true, error: null};
    case types.FETCH_TRAININGS_SUCCESS:
      return {...state, trainingsLoading: false, trainings: action.trainings, error: null};
    case types.FETCH_TRAININGS_FAILURE:
      return {...state, trainingsLoading: false, error: action.error};

    case types.FETCH_ONE_TRAINING_REQUEST:
      return {...state, oneTrainingLoading: true, error: null};
    case types.FETCH_ONE_TRAINING_SUCCESS:
      return {...state, oneTrainingLoading: false, oneTraining: action.oneTraining, error: null};
    case types.FETCH_ONE_TRAINING_FAILURE:
      return {...state, oneTrainingLoading: false, error: action.error};

    case types.JOIN_TO_TRAINING_REQUEST:
      return {...state, loading: true, joinError: null};
    case types.JOIN_TO_TRAINING_SUCCESS:
      return {...state, loading: false, joinError: null};
    case types.JOIN_TO_TRAINING_FAILURE:
      return {...state, loading: false, joinError: action.error};

    case types.FETCH_USER_TRAININGS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_USER_TRAININGS_SUCCESS:
      return {...state, loading: false, userTrainings: action.userTrainings, error: null};
    case types.FETCH_USER_TRAININGS_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;