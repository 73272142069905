import * as types from '../actionTypes';

const initialState = {
  contacts: [],
  info: [],
  social:[],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FOOTER_CONTACTS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_FOOTER_CONTACTS_SUCCESS:
      return {...state, loading: false, contacts: action.contacts, error: null};
    case types.FETCH_FOOTER_CONTACTS_FAILURE:
      return {...state, loading: false, error: action.error};
    case types.FETCH_FOOTER_INFO_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_FOOTER_INFO_SUCCESS:
      return {...state, loading: false, info: action.info, error: null};
    case types.FETCH_FOOTER_INFO_FAILURE:
      return {...state, loading: false, error: action.error};
    case types.FETCH_FOOTER_SOCIALS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_FOOTER_SOCIALS_SUCCESS:
      return {...state, loading: false, social: action.social, error: null};
    case types.FETCH_FOOTER_SOCIALS_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;