export default [
  {
    title: 'Лучшие практики МСУ',
    description: 'Местное самоуправление – гарантированное настоящей Конституцией право и реальная возможность местных сообществ самостоятельно в своих интересах и под свою ответственность решать вопросы местного значения. (Статья 110 Конституции Кыргызской Республики)',
    canonical: '/',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  },
  {
    title: 'О портале',
    description: 'Портал ЛП и журнал «Муниципалитет» созданы для руководителей и сотрудников органов МСУ, депутатов местных кенешей, представителей местных сообществ, исследователей, студентов и преподавателей в сфере государственного и муниципального управления, других заинтересованных лиц.',
    canonical: '/about',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react, meta, document, html, tags'
      }
    }
  }
]