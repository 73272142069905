import React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Router} from 'react-router-dom';
import { Provider } from 'react-redux';
// import ReactGa, {pageview} from 'react-ga';
import DocumentMeta from 'react-document-meta';
import meta from './meta';

import store, { history } from './store/configureStore';
import "./i18n";

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import './sass/style.sass';


// export const initGa = trackingId => {
//   ReactGa.initialize(trackingId);
// };
//
// export const pageView = () => {
//   pageview(window.location.pathname + window.location.search);
// };


const htmlTag = document.querySelector('html');
const DynamicSize = () => {
  const width = document.documentElement.clientWidth;

  if (width <= 1668 && width > 813)
    htmlTag.setAttribute('style',`font-size: ${(width / 1668).toFixed(2)}px`);
  else if (width <= 813 && width > 640)
    htmlTag.setAttribute('style',`font-size: ${(width / 640).toFixed(2)}px`);
  else if (width <= 640 && width > 576)
    htmlTag.setAttribute('style',`font-size: ${(width / 576).toFixed(2)}px`);
  else if (width <= 576 && width > 480)
    htmlTag.setAttribute('style',`font-size: ${(width / 480).toFixed(2)}px`);
  else htmlTag.setAttribute('style', `font-size: 1px`);
};
window.onresize = () => DynamicSize();
DynamicSize();

let myMeta, index

index = meta.findIndex(el => el.canonical === window.location.pathname);

myMeta = meta[index] || meta[0];

const app = (
  <DocumentMeta {...myMeta}>
    <Provider store={store}>
      <Router history={history}>
        <App history={history}/>
      </Router>
    </Provider>
  </DocumentMeta>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
