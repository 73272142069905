export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const GET_THEMATICS_LIST_REQUEST = 'GET_THEMATICS_LIST_REQUEST';
export const GET_THEMATICS_LIST_SUCCESS = 'GET_THEMATICS_LIST_SUCCESS';
export const GET_THEMATICS_LIST_FAILURE = 'GET_THEMATICS_LIST_FAILURE';

export const GET_NEWS_LIST_REQUEST = 'GET_NEWS_LIST_REQUEST';
export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS';
export const GET_NEWS_LIST_FAILURE = 'GET_NEWS_LIST_FAILURE';

export const FETCH_ASIDE_CATEGORIES_REQUEST = 'FETCH_ASIDE_CATEGORIES_REQUEST';
export const FETCH_ASIDE_CATEGORIES_SUCCESS = 'FETCH_ASIDE_CATEGORIES_SUCCESS';
export const FETCH_ASIDE_CATEGORIES_FAILURE = 'FETCH_ASIDE_CATEGORIES_FAILURE';

export const FETCH_NPA_DATA_REQUEST = 'FETCH_NPA_DATA_REQUEST';
export const FETCH_NPA_DATA_SUCCESS = 'FETCH_NPA_DATA_SUCCESS';
export const FETCH_NPA_DATA_FAILURE = 'FETCH_NPA_DATA_FAILURE';

export const FETCH_ABOUT_PORTAL_REQUEST = 'FETCH_ABOUT_PORTAL_REQUEST';
export const FETCH_ABOUT_PORTAL_SUCCESS = 'FETCH_ABOUT_PORTAL_SUCCESS';
export const FETCH_ABOUT_PORTAL_FAILURE = 'FETCH_ABOUT_PORTAL_FAILURE';

export const FETCH_ABOUT_PORTAL_ORGANIZATIONS_REQUEST = 'FETCH_ABOUT_PORTAL_ORGANIZATIONS_REQUEST';
export const FETCH_ABOUT_PORTAL_ORGANIZATIONS_SUCCESS = 'FETCH_ABOUT_PORTAL_ORGANIZATIONS_SUCCESS';
export const FETCH_ABOUT_PORTAL_ORGANIZATIONS_FAILURE = 'FETCH_ABOUT_PORTAL_ORGANIZATIONS_FAILURE';

export const FETCH_ABOUT_FAQ_REQUEST = 'FETCH_ABOUT_FAQ_REQUEST';
export const FETCH_ABOUT_FAQ_SUCCESS = 'FETCH_ABOUT_FAQ_SUCCESS';
export const FETCH_ABOUT_FAQ_FAILURE = 'FETCH_ABOUT_FAQ_FAILURE';

export const FETCH_FAQ_REQUEST = 'FETCH_FAQ_REQUEST';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAILURE = 'FETCH_FAQ_FAILURE';

export const FETCH_TYPICAL_ERRORS_REQUEST = 'FETCH_TYPICAL_ERRORS_REQUEST';
export const FETCH_TYPICAL_ERRORS_SUCCESS = 'FETCH_TYPICAL_ERRORS_SUCCESS';
export const FETCH_TYPICAL_ERRORS_FAILURE = 'FETCH_TYPICAL_ERRORS_FAILURE';

export const FETCH_QUESTIONS_REQUEST = 'FETCH_QUESTIONS_REQUEST';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAILURE = 'FETCH_QUESTIONS_FAILURE';

export const FETCH_ANSWERS_REQUEST = 'FETCH_ANSWERS_REQUEST';
export const FETCH_ANSWERS_SUCCESS = 'FETCH_ANSWERS_SUCCESS';
export const FETCH_ANSWERS_FAILURE = 'FETCH_ANSWERS_FAILURE';

export const FETCH_ADVERTISING_REQUEST = 'FETCH_ADVERTISING_REQUEST';
export const FETCH_ADVERTISING_SUCCESS = 'FETCH_ADVERTISING_SUCCESS';
export const FETCH_ADVERTISING_FAILURE = 'FETCH_ADVERTISING_FAILURE';

export const FETCH_ALL_EVENTS_REQUEST = 'FETCH_ALL_EVENTS_REQUEST';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_ALL_EVENTS_FAILURE = 'FETCH_ALL_EVENTS_FAILURE';

export const FETCH_SINGLE_EVENT_REQUEST = 'FETCH_SINGLE_EVENT_REQUEST';
export const FETCH_SINGLE_EVENT_SUCCESS = 'FETCH_SINGLE_EVENT_SUCCESS';
export const FETCH_SINGLE_EVENT_FAILURE = 'FETCH_SINGLE_EVENT_FAILURE';

export const FETCH_TRAINING_MATERIALS_REQUEST = 'FETCH_TRAINING_MATERIALS_REQUEST';
export const FETCH_TRAINING_MATERIALS_SUCCESS = 'FETCH_TRAINING_MATERIALS_SUCCESS';
export const FETCH_TRAINING_MATERIALS_FAILURE = 'FETCH_TRAINING_MATERIALS_FAILURE';

export const FETCH_THEMATICS_REQUEST = 'FETCH_THEMATICS_REQUEST';
export const FETCH_THEMATICS_SUCCESS = 'FETCH_THEMATICS_SUCCESS';
export const FETCH_THEMATICS_FAILURE = 'FETCH_THEMATICS_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_MATERIAL_TYPES_REQUEST = 'FETCH_MATERIAL_TYPES_REQUEST';
export const FETCH_MATERIAL_TYPES_SUCCESS = 'FETCH_MATERIAL_TYPES_SUCCESS';
export const FETCH_MATERIAL_TYPES_FAILURE = 'FETCH_MATERIAL_TYPES_FAILURE';

export const FETCH_ONE_TRAINING_MATERIAL_REQUEST = 'FETCH_ONE_TRAINING_MATERIAL_REQUEST';
export const FETCH_ONE_TRAINING_MATERIAL_SUCCESS = 'FETCH_ONE_TRAINING_MATERIAL_SUCCESS';
export const FETCH_ONE_TRAINING_MATERIAL_FAILURE = 'FETCH_ONE_TRAINING_MATERIAL_FAILURE';

export const FETCH_TRAININGS_REQUEST = 'FETCH_TRAININGS_REQUEST';
export const FETCH_TRAININGS_SUCCESS = 'FETCH_TRAININGS_SUCCESS';
export const FETCH_TRAININGS_FAILURE = 'FETCH_TRAININGS_FAILURE';

export const FETCH_ONE_TRAINING_REQUEST = 'FETCH_ONE_TRAINING_REQUEST';
export const FETCH_ONE_TRAINING_SUCCESS = 'FETCH_ONE_TRAINING_SUCCESS';
export const FETCH_ONE_TRAINING_FAILURE = 'FETCH_ONE_TRAINING_FAILURE';

export const FETCH_NEED_HELPS_REQUEST = 'FETCH_NEED_HELPS_REQUEST';
export const FETCH_NEED_HELPS_SUCCESS = 'FETCH_NEED_HELPS_SUCCESS';
export const FETCH_NEED_HELPS_FAILURE = 'FETCH_NEED_HELPS_FAILURE';

export const FETCH_NEED_HELP_REQUEST = 'FETCH_NEED_HELP_REQUEST';
export const FETCH_NEED_HELP_SUCCESS = 'FETCH_NEED_HELP_SUCCESS';
export const FETCH_NEED_HELP_FAILURE = 'FETCH_NEED_HELP_FAILURE';

export const FETCH_AREAS_REQUEST = 'FETCH_AREAS_REQUEST';
export const FETCH_AREAS_SUCCESS = 'FETCH_AREAS_SUCCESS';
export const FETCH_AREAS_FAILURE = 'FETCH_AREAS_FAILURE';

export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export const FETCH_DISTRICTS_REQUEST = 'FETCH_DISTRICTS_REQUEST';
export const FETCH_DISTRICTS_SUCCESS = 'FETCH_DISTRICTS_SUCCESS';
export const FETCH_DISTRICTS_FAILURE = 'FETCH_DISTRICTS_FAILURE';

export const FETCH_LOCAL_GOVERNMENTS_REQUEST = 'FETCH_LOCAL_GOVERNMENTS_REQUEST';
export const FETCH_LOCAL_GOVERNMENTS_SUCCESS = 'FETCH_LOCAL_GOVERNMENTS_SUCCESS';
export const FETCH_LOCAL_GOVERNMENTS_FAILURE = 'FETCH_LOCAL_GOVERNMENTS_FAILURE';

export const FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_INVEST_PROJECTS_REQUEST = 'FETCH_INVEST_PROJECTS_REQUEST';
export const FETCH_INVEST_PROJECTS_SUCCESS = 'FETCH_INVEST_PROJECTS_SUCCESS';
export const FETCH_INVEST_PROJECTS_FAILURE = 'FETCH_INVEST_PROJECTS_FAILURE';

export const FETCH_INVEST_PROJECT_REQUEST = 'FETCH_INVEST_PROJECT_REQUEST';
export const FETCH_INVEST_PROJECT_SUCCESS = 'FETCH_INVEST_PROJECT_SUCCESS';
export const FETCH_INVEST_PROJECT_FAILURE = 'FETCH_INVEST_PROJECT_FAILURE';

export const FETCH_BEST_PRACTICES_REQUEST = 'FETCH_BEST_PRACTICES_REQUEST';
export const FETCH_BEST_PRACTICES_SUCCESS = 'FETCH_BEST_PRACTICES_SUCCESS';
export const FETCH_BEST_PRACTICES_FAILURE = 'FETCH_BEST_PRACTICES_FAILURE';

export const FETCH_BEST_PRACTICE_REQUEST = 'FETCH_BEST_PRACTICE_REQUEST';
export const FETCH_BEST_PRACTICE_SUCCESS = 'FETCH_BEST_PRACTICE_SUCCESS';
export const FETCH_BEST_PRACTICE_FAILURE = 'FETCH_BEST_PRACTICE_FAILURE';

export const FETCH_PRACTICE_ORGANIZATION_FORM_REQUEST = 'FETCH_PRACTICE_ORGANIZATION_FORM_REQUEST';
export const FETCH_PRACTICE_ORGANIZATION_FORM_SUCCESS = 'FETCH_PRACTICE_ORGANIZATION_FORM_SUCCESS';
export const FETCH_PRACTICE_ORGANIZATION_FORM_FAILURE = 'FETCH_PRACTICE_ORGANIZATION_FORM_FAILURE';

export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE';

export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';

export const FETCH_FORUMS_REQUEST = 'FETCH_FORUMS_REQUEST';
export const FETCH_FORUMS_SUCCESS = 'FETCH_FORUMS_SUCCESS';
export const FETCH_FORUMS_FAILURE = 'FETCH_FORUMS_FAILURE';

export const CREATE_FORUM_REQUEST = 'CREATE_FORUM_REQUEST';
export const CREATE_FORUM_SUCCESS = 'CREATE_FORUM_SUCCESS';
export const CREATE_FORUM_FAILURE = 'CREATE_FORUM_FAILURE';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';

export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';

export const ADD_VOTE_REQUEST = 'ADD_VOTE_REQUEST';
export const ADD_VOTE_SUCCESS = 'ADD_VOTE_SUCCESS';
export const ADD_VOTE_FAILURE = 'ADD_VOTE_FAILURE';

export const CHECK_VOTED_REQUEST = 'CHECK_VOTED_REQUEST';
export const CHECK_VOTED_SUCCESS = 'CHECK_VOTED_SUCCESS';
export const CHECK_VOTED_FAILURE = 'CHECK_VOTED_FAILURE';

export const CREATE_COMMENTS_REQUEST = "CREATE_COMMENTS_REQUEST";
export const CREATE_COMMENTS_SUCCESS = "CREATE_COMMENTS_SUCCESS";
export const CREATE_COMMENTS_FAILURE = "CREATE_COMMENTS_FAILURE";

export const PUT_COMMENTS_REQUEST = "PUT_COMMENTS_REQUEST";
export const PUT_COMMENTS_SUCCESS = "PUT_COMMENTS_SUCCESS";
export const PUT_COMMENTS_FAILURE = "PUT_COMMENTS_FAILURE";

export const DELETE_COMMENTS_REQUEST = "DELETE_COMMENTS_REQUEST";
export const DELETE_COMMENTS_SUCCESS = "DELETE_COMMENTS_SUCCESS";
export const DELETE_COMMENTS_FAILURE = "DELETE_COMMENTS_FAILURE";

export const FETCH_FOOTER_CONTACTS_REQUEST = 'FETCH_FOOTER_CONTACTS_REQUEST';
export const FETCH_FOOTER_CONTACTS_SUCCESS = 'FETCH_FOOTER_CONTACTS_SUCCESS';
export const FETCH_FOOTER_CONTACTS_FAILURE = 'FETCH_FOOTER_CONTACTS_FAILURE';

export const FETCH_FOOTER_INFO_REQUEST = 'FETCH_FOOTER_INFO_REQUEST';
export const FETCH_FOOTER_INFO_SUCCESS = 'FETCH_FOOTER_INFO_SUCCESS';
export const FETCH_FOOTER_INFO_FAILURE = 'FETCH_FOOTER_INFO_FAILURE';

export const FETCH_FOOTER_SOCIALS_REQUEST = 'FETCH_FOOTER_SOCIALS_REQUEST';
export const FETCH_FOOTER_SOCIALS_SUCCESS = 'FETCH_FOOTER_SOCIALS_SUCCESS';
export const FETCH_FOOTER_SOCIALS_FAILURE = 'FETCH_FOOTER_SOCIALS_FAILURE';

export const FETCH_HEADER_REQUEST = 'FETCH_HEADER_REQUEST';
export const FETCH_HEADER_SUCCESS = 'FETCH_HEADER_SUCCESS';
export const FETCH_HEADER_FAILURE = 'FETCH_HEADER_FAILURE';

export const FETCH_DOCUMENT_TYPES_REQUEST = 'FETCH_DOCUMENT_TYPES_REQUEST';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_DOCUMENT_TYPES_FAILURE = 'FETCH_DOCUMENT_TYPES_FAILURE';

export const CREATE_USER_PRACTICE_REQUEST = 'CREATE_USER_PRACTICE_REQUEST';
export const CREATE_USER_PRACTICE_SUCCESS = 'CREATE_USER_PRACTICE_SUCCESS';
export const CREATE_USER_PRACTICE_FAILURE = 'CREATE_USER_PRACTICE_FAILURE';

export const FETCH_USER_PRACTICES_REQUEST = 'FETCH_USER_PRACTICES_REQUEST';
export const FETCH_USER_PRACTICES_SUCCESS = 'FETCH_USER_PRACTICES_SUCCESS';
export const FETCH_USER_PRACTICES_FAILURE = 'FETCH_USER_PRACTICES_FAILURE';

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const FETCH_PRACTICE_VOTES_REQUEST = 'FETCH_PRACTICE_VOTES_REQUEST';
export const FETCH_PRACTICE_VOTES_SUCCESS = 'FETCH_PRACTICE_VOTES_SUCCESS';
export const FETCH_PRACTICE_VOTES_FAILURE = 'FETCH_PRACTICE_VOTES_FAILURE';
export const FETCH_PRACTICE_VOTES_DEFAULT = 'FETCH_PRACTICE_VOTES_DEFAULT';

export const JOIN_TO_EVENT_REQUEST = 'JOIN_TO_EVENT_REQUEST';
export const JOIN_TO_EVENT_SUCCESS = 'JOIN_TO_EVENT_SUCCESS';
export const JOIN_TO_EVENT_FAILURE = 'JOIN_TO_EVENT_FAILURE';

export const FETCH_USER_EVENTS_REQUEST = 'FETCH_USER_EVENTS_REQUEST';
export const FETCH_USER_EVENTS_SUCCESS = 'FETCH_USER_EVENTS_SUCCESS';
export const FETCH_USER_EVENTS_FAILURE = 'FETCH_USER_EVENTS_FAILURE';

export const JOIN_TO_TRAINING_REQUEST = 'JOIN_TO_TRAINING_REQUEST';
export const JOIN_TO_TRAINING_SUCCESS = 'JOIN_TO_TRAINING_SUCCESS';
export const JOIN_TO_TRAINING_FAILURE = 'JOIN_TO_TRAINING_FAILURE';

export const FETCH_USER_TRAININGS_REQUEST = 'FETCH_USER_TRAININGS_REQUEST';
export const FETCH_USER_TRAININGS_SUCCESS = 'FETCH_USER_TRAININGS_SUCCESS';
export const FETCH_USER_TRAININGS_FAILURE = 'FETCH_USER_TRAININGS_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FETCH_ADDITIONAL_MATERIALS_REQUEST = 'FETCH_ADDITIONAL_MATERIALS_REQUEST';
export const FETCH_ADDITIONAL_MATERIALS_SUCCESS = 'FETCH_ADDITIONAL_MATERIALS_SUCCESS';
export const FETCH_ADDITIONAL_MATERIALS_FAILURE = 'FETCH_ADDITIONAL_MATERIALS_FAILURE';

export const FETCH_ADDITIONAL_MATERIAL_TYPES_REQUEST = 'FETCH_ADDITIONAL_MATERIAL_TYPES_REQUEST';
export const FETCH_ADDITIONAL_MATERIAL_TYPES_SUCCESS = 'FETCH_ADDITIONAL_MATERIAL_TYPES_SUCCESS';
export const FETCH_ADDITIONAL_MATERIAL_TYPES_FAILURE = 'FETCH_ADDITIONAL_MATERIAL_TYPES_FAILURE';

export const FETCH_JOURNALS_REQUEST = 'FETCH_JOURNALS_REQUEST';
export const FETCH_JOURNALS_SUCCESS = 'FETCH_JOURNALS_SUCCESS';
export const FETCH_JOURNALS_FAILURE = 'FETCH_JOURNALS_FAILURE';

export const FETCH_ANALYTICAL_REVIEW_REQUEST = 'FETCH_ANALYTICAL_REVIEW_REQUEST';
export const FETCH_ANALYTICAL_REVIEW_SUCCESS = 'FETCH_ANALYTICAL_REVIEW_SUCCESS';
export const FETCH_ANALYTICAL_REVIEW_FAILURE = 'FETCH_ANALYTICAL_REVIEW_FAILURE';

export const FETCH_ANNOUNCEMENT_REQUEST = 'FETCH_ANNOUNCEMENT_REQUEST';
export const FETCH_ANNOUNCEMENT_SUCCESS = 'FETCH_ANNOUNCEMENT_SUCCESS';
export const FETCH_ANNOUNCEMENT_FAILURE = 'FETCH_ANNOUNCEMENT_FAILURE'

export const FETCH_PRIVACY_REQUEST = 'FETCH_PRIVACY_REQUEST';
export const FETCH_PRIVACY_SUCCESS = 'FETCH_PRIVACY_SUCCESS';
export const FETCH_PRIVACY_FAILURE = 'FETCH_PRIVACY_FAILURE';