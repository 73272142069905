import * as types from '../actionTypes';

const initialState = {
  roles: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROLES_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ROLES_SUCCESS:
      return {...state, loading: false, roles: action.roles, error: null};
    case types.FETCH_ROLES_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;