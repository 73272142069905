import * as types from '../actionTypes';

const initialState = {
  trainingMaterials: [],
  trainingMaterialsLoading: false,
  trainingMaterial: [],
  trainingMaterialLoading: false,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_TRAINING_MATERIALS_REQUEST:
      return {...state, trainingMaterialsLoading: true, error: null};
    case types.FETCH_TRAINING_MATERIALS_SUCCESS:
      return {...state, trainingMaterialsLoading: false, trainingMaterials: action.trainingMaterials, error: null};
    case types.FETCH_TRAINING_MATERIALS_FAILURE:
      return {...state, trainingMaterialsLoading: false, error: action.error};

    case types.FETCH_ONE_TRAINING_MATERIAL_REQUEST:
      return {...state, trainingMaterialLoading: true, error: null};
    case types.FETCH_ONE_TRAINING_MATERIAL_SUCCESS:
      return {...state, trainingMaterialLoading: false, trainingMaterial: action.trainingMaterial, error: null};
    case types.FETCH_ONE_TRAINING_MATERIAL_FAILURE:
      return {...state, trainingMaterialLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;