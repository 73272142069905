import * as types from '../actionTypes';

const initialState = {
  investProjects: [],
  investProjectsLoading: false,
  investProject: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_INVEST_PROJECTS_REQUEST:
      return {...state, investProjectsLoading: true, error: null};
    case types.FETCH_INVEST_PROJECTS_SUCCESS:
      return {...state, investProjectsLoading: false, investProjects: action.investProjects, error: null};
    case types.FETCH_INVEST_PROJECTS_FAILURE:
      return {...state, investProjectsLoading: false, error: action.error};

    case types.FETCH_INVEST_PROJECT_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_INVEST_PROJECT_SUCCESS:
      return {...state, loading: false, investProject: action.investProject, error: null};
    case types.FETCH_INVEST_PROJECT_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;