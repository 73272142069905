import * as types from '../actionTypes';

const initialState = {
  areas: [],
  countries: [],
  districts: [],
  localGovernments: [],
  cities: [],
  areasLoading: false,
  countriesLoading: false,
  districtsLoading: false,
  localGovernmentsLoading: false,
  citiesLoading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_AREAS_REQUEST:
      return {...state, areasLoading: true, error: null};
    case types.FETCH_AREAS_SUCCESS:
      return {...state, areasLoading: false, areas: action.areas, error: null};
    case types.FETCH_AREAS_FAILURE:
      return {...state, areasLoading: false, error: action.error};

    case types.FETCH_COUNTRIES_REQUEST:
      return {...state, countriesLoading: true, error: null};
    case types.FETCH_COUNTRIES_SUCCESS:
      return {...state, countriesLoading: false, countries: action.countries, error: null};
    case types.FETCH_COUNTRIES_FAILURE:
      return {...state, countriesLoading: false, error: action.error};

    case types.FETCH_DISTRICTS_REQUEST:
      return {...state, districtsLoading: true, error: null};
    case types.FETCH_DISTRICTS_SUCCESS:
      return {...state, districtsLoading: false, districts: action.districts, error: null};
    case types.FETCH_DISTRICTS_FAILURE:
      return {...state, districtsLoading: false, error: action.error};

    case types.FETCH_LOCAL_GOVERNMENTS_REQUEST:
      return {...state, localGovernmentsLoading: true, error: null};
    case types.FETCH_LOCAL_GOVERNMENTS_SUCCESS:
      return {...state, localGovernmentsLoading: false, localGovernments: action.localGovernments, error: null};
    case types.FETCH_LOCAL_GOVERNMENTS_FAILURE:
      return {...state, localGovernmentsLoading: false, error: action.error};

    case types.FETCH_CITIES_REQUEST:
      return {...state, citiesLoading: true, error: null};
    case types.FETCH_CITIES_SUCCESS:
      return {...state, citiesLoading: false, cities: action.cities, error: null};
    case types.FETCH_CITIES_FAILURE:
      return {...state, citiesLoading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;