import * as types from '../actionTypes';

const initialState = {
  users: [],
  user: {},
  currentUser: {},
  currentUserLoading: false,
  currentUserError: null,
  loading: false,
  error: null,
  registerUserError: null,
  resetPasswordError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_USERS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_USERS_SUCCESS:
      return {...state, loading: false, users: action.users, error: null};
    case types.FETCH_USERS_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.REGISTER_USER_REQUEST:
      return {...state, loading: true, registerUserError: null};
    case types.REGISTER_USER_SUCCESS:
      return {...state, loading: false, user: action.user, registerUserError: null};
    case types.REGISTER_USER_FAILURE:
      return {...state, loading: false, registerUserError: action.registerUserError};

    case types.FETCH_CURRENT_USER_REQUEST:
      return {...state, currentUserLoading: true, currentUserError: null};
    case types.FETCH_CURRENT_USER_SUCCESS:
      return {...state, currentUserLoading: false, currentUser: action.currentUser, currentUserError: null};
    case types.FETCH_CURRENT_USER_FAILURE:
      return {...state, currentUserLoading: false, currentUser: {}, currentUserError: action.error};

    case types.UPDATE_USER_REQUEST:
      return {...state, loading: true, error: null};
    case types.UPDATE_USER_SUCCESS:
      return {...state, loading: false, user: action.user, error: null};
    case types.UPDATE_USER_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.RESET_PASSWORD_REQUEST:
      return {...state, loading: true, resetPasswordError: null};
    case types.RESET_PASSWORD_SUCCESS:
      return {...state, loading: false, resetPasswordError: null};
    case types.RESET_PASSWORD_FAILURE:
      return {...state, loading: false, resetPasswordError: action.error};

    case types.CHANGE_PASSWORD_REQUEST:
      return {...state, loading: true, error: null};
    case types.CHANGE_PASSWORD_SUCCESS:
      return {...state, loading: false, error: null};
    case types.CHANGE_PASSWORD_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;