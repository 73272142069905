import * as types from '../actionTypes';

const initialState = {
  thematicList: [],
  thematicLoading: false,
  newsList: [],
  newsLoading: false,
  questions: [],
  questionsLoading: false,
  answers: [],
  advertising: [],
  advertisingLoading: false,
  announcements: [],
  loadingAnnouncement: false,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_THEMATICS_LIST_REQUEST:
      return {...state, thematicLoading: true, error: null};
    case types.GET_THEMATICS_LIST_SUCCESS:
      return {...state, thematicList: action.data, thematicLoading: false, error: null};
    case types.LOGIN_FAILURE:
      return {...state, thematicLoading: false, error: action.error};

    case types.GET_NEWS_LIST_REQUEST:
      return {...state, newsLoading: true, error: null};
    case types.GET_NEWS_LIST_SUCCESS:
      return {...state, newsList: action.data, newsLoading: false, error: null};
    case types.GET_NEWS_LIST_FAILURE:
      return {...state, newsLoading: false, error: action.error};

    case types.FETCH_QUESTIONS_REQUEST:
      return {...state, questionsLoading: true, error: null};
    case types.FETCH_QUESTIONS_SUCCESS:
      return {...state, questionsLoading: false, questions: action.questions, error: null};
    case types.FETCH_QUESTIONS_FAILURE:
      return {...state, questionsLoading: false, error: action.error};

    case types.FETCH_ANSWERS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ANSWERS_SUCCESS:
      return {...state, loading: false, answers: action.answers, error: null};
    case types.FETCH_ANSWERS_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.FETCH_ADVERTISING_REQUEST:
      return {...state, advertisingLoading: true, error: null};
    case types.FETCH_ADVERTISING_SUCCESS:
      return {...state, advertisingLoading: false, advertising: action.advertising, error: null};
    case types.FETCH_ADVERTISING_FAILURE:
      return {...state, advertisingLoading: false, error: action.error};

    case types.FETCH_ANNOUNCEMENT_REQUEST:
      return {...state, loadingAnnouncement: true, error: null};
    case types.FETCH_ANNOUNCEMENT_SUCCESS:
      return {...state, loadingAnnouncement: false, announcements: action.announcements, error: null};
    case types.FETCH_ANNOUNCEMENT_FAILURE:
      return {...state, loadingAnnouncement: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;