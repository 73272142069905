import * as types from '../actionTypes';

const initialState = {
  privacy: [],
  loading: false,
  error: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PRIVACY_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_PRIVACY_SUCCESS:
      return {...state, loading: false, privacy: action.privacy, error: null};
    case types.FETCH_PRIVACY_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
}

export default reducer;