import * as types from '../actionTypes';

const initialState = {
  aboutPortal: [],
  aboutPortalOrganizations: [],
  aboutFaq: [],
  portalLoading: false,
  organizationsLoading: false,
  faqLoading: false,
  portalError: null,
  organizationsError: null,
  faqError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ABOUT_PORTAL_REQUEST:
      return {...state, portalLoading: true, portalError: null};
    case types.FETCH_ABOUT_PORTAL_SUCCESS:
      return {...state, portalLoading: false, aboutPortal: action.aboutPortal, portalError: null};
    case types.FETCH_ABOUT_PORTAL_FAILURE:
      return {...state, portalLoading: false, portalError: action.error};
    case types.FETCH_ABOUT_PORTAL_ORGANIZATIONS_REQUEST:
      return {...state, organizationsLoading: true, organizationsError: null};
    case types.FETCH_ABOUT_PORTAL_ORGANIZATIONS_SUCCESS:
      return {...state, organizationsLoading: false, aboutPortalOrganizations: action.aboutPortalOrganizations, organizationsError: null};
    case types.FETCH_ABOUT_PORTAL_ORGANIZATIONS_FAILURE:
      return {...state, organizationsLoading: false, organizationsError: action.error};
    case types.FETCH_ABOUT_FAQ_REQUEST:
      return {...state, faqLoading: true, faqError: null};
    case types.FETCH_ABOUT_FAQ_SUCCESS:
      return {...state, faqLoading: false, aboutFaq: action.aboutFaq, faqError: null};
    case types.FETCH_ABOUT_FAQ_FAILURE:
      return {...state, faqLoading: false, faqError: action.error};
    default:
      return {...state};
  }
};

export default reducer;