import * as types from '../actionTypes';

const initialState = {
  npa: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_NPA_DATA_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_NPA_DATA_SUCCESS:
      return {...state, loading: false, npa: action.npa, error: null};
    case types.FETCH_NPA_DATA_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;