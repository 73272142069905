import * as types from '../actionTypes';

const initialState = {
  bestPractices: [],
  bestPracticesLoading: false,
  bestPractice: [],
  bestPracticeLoading: false,
  userBestPractice: {},
  userBestPractices: {},
  id: '',
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_BEST_PRACTICES_REQUEST:
      return {...state, bestPracticesLoading: true, error: null};
    case types.FETCH_BEST_PRACTICES_SUCCESS:
      return {...state, bestPracticesLoading: false, bestPractices: action.bestPractices, error: null};
    case types.FETCH_BEST_PRACTICES_FAILURE:
      return {...state, bestPracticesLoading: false, error: action.error};

    case types.FETCH_BEST_PRACTICE_REQUEST:
      return {...state, bestPracticeLoading: true, error: null};
    case types.FETCH_BEST_PRACTICE_SUCCESS:
      return {...state, bestPracticeLoading: false, bestPractice: action.bestPractice, error: null};
    case types.FETCH_BEST_PRACTICE_FAILURE:
      return {...state, bestPracticeLoading: false, error: action.error};

    case types.CREATE_USER_PRACTICE_REQUEST:
      return {...state, loading: true, error: null};
    case types.CREATE_USER_PRACTICE_SUCCESS:
      return {...state, loading: false, userBestPractice: action.userBestPractice, id: action.id, error: null};
    case types.CREATE_USER_PRACTICE_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.FETCH_USER_PRACTICES_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_USER_PRACTICES_SUCCESS:
      return {...state, loading: false, userBestPractices: action.userBestPractices, error: null};
    case types.FETCH_USER_PRACTICES_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;