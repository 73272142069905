import * as types from '../actionTypes';

const initialState = {
  language: 'kg',
  account: null,
  token: null,
  loading: false,
  error: null,
  loginError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.CHANGE_LANGUAGE:
      return {...state, language: action.language};

    case types.LOGIN_REQUEST:
      return {...state, loading: true, loginError: null};
    case types.LOGIN_SUCCESS:
      return {...state, account: action.data, token: action.token, loading: false, loginError: null};
    case types.LOGIN_FAILURE:
      return {...state, loading: false, loginError: action.error};

    case types.LOGOUT_REQUEST:
      return {...state, loading: true, error: null};
    case types.LOGOUT_SUCCESS:
      return {...initialState};
    case types.LOGOUT_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.REFRESH_TOKEN_REQUEST:
      return {...state, loading: true, error: null};
    case types.REFRESH_TOKEN_SUCCESS:
      return {...state, account: action.data, token: action.token, loading: false, error: null};
    case types.REFRESH_TOKEN_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;
