import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import kg from './kg';
import ru from './ru';

const resources = {
  en: {
    translation: en
  },
  kg: {
    translation: kg
  },
  ru: {
    translation: ru
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "kg",
    fallbackLng: 'kg'
  });

export default i18n;
