import * as types from '../actionTypes';

const initialState = {
  needHelps: [],
  needHelpsLoading: false,
  needHelp: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_NEED_HELPS_REQUEST:
      return {...state, needHelpsLoading: true, error: null};
    case types.FETCH_NEED_HELPS_SUCCESS:
      return {...state, needHelpsLoading: false, needHelps: action.needHelps, error: null};
    case types.FETCH_NEED_HELPS_FAILURE:
      return {...state, needHelpsLoading: false, error: action.error};

    case types.FETCH_NEED_HELP_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_NEED_HELP_SUCCESS:
      return {...state, loading: false, needHelp: action.needHelp, error: null};
    case types.FETCH_NEED_HELP_FAILURE:
      return {...state, loading: false, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;