import thunkMiddleware from "redux-thunk";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {routerMiddleware, routerReducer} from "react-router-redux";
import {createBrowserHistory} from "history";

import {saveState, loadState} from "./localStorage";

import main from "./reducers/main";
import authReducer from "./reducers/auth";
import asideCategoriesReducer from './reducers/asideCategories';
import npaReducer from './reducers/npa';
import aboutPortalReducer from './reducers/aboutPortal';
import faqReducer from './reducers/faq';
import footerReducer from './reducers/footer';
import headerReducer from './reducers/header';
import typicalErrorsReducer from './reducers/typicalErrors';
import eventsReducer from './reducers/events';
import trainingMaterialsReducer from './reducers/trainingMaterials';
import filtersReducer from './reducers/filters';
import trainingsReducer from './reducers/trainings';
import needHelpsReducer from './reducers/needHelps';
import lgFormDataReducer from './reducers/lgFormData';
import usersReducer from './reducers/users';
import investProjectsReducer from './reducers/investProjects';
import bestPracticesReducer from './reducers/bestPractices';
import practiceOrganizationFormReducer from './reducers/practiceOrganizationForm';
import documentsReducer from './reducers/documents';
import commentsReducer from './reducers/comments';
import forumReducer from './reducers/forums';
import rolesReducer from './reducers/roles';
import voteReducer from './reducers/vote';
import additionalMaterialsReducer from './reducers/additionalMaterials';
import journalsReducer from './reducers/journals';
import analyticalReviewsReducer from './reducers/analyticalReview';
import privacyReducer from './reducers/privacy';

const rootReducer = combineReducers({
  auth: authReducer,
  main: main,
  asideCategories: asideCategoriesReducer,
  npa: npaReducer,
  aboutPortal: aboutPortalReducer,
  faq: faqReducer,
  footer: footerReducer,
  header: headerReducer,
  typicalErrors: typicalErrorsReducer,
  events: eventsReducer,
  trainingMaterials: trainingMaterialsReducer,
  filters: filtersReducer,
  trainings: trainingsReducer,
  needHelps: needHelpsReducer,
  lgFormData: lgFormDataReducer,
  users: usersReducer,
  investProjects: investProjectsReducer,
  bestPractices: bestPracticesReducer,
  practiceOrganizationForm: practiceOrganizationFormReducer,
  documents: documentsReducer,
  comments: commentsReducer,
  forums: forumReducer,
  roles: rolesReducer,
  vote: voteReducer,
  additionalMaterials: additionalMaterialsReducer,
  journals: journalsReducer,
  analyticalReview: analyticalReviewsReducer,
  privacy: privacyReducer,
  routing: routerReducer,
});

export const history = createBrowserHistory();

const middleware = [
  thunkMiddleware,
  routerMiddleware(history)
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composeEnhancers(applyMiddleware(...middleware));

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
  });
});

export default store;
