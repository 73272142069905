import * as types from '../actionTypes';

const initialState = {
  journals: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_JOURNALS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_JOURNALS_SUCCESS:
      return {...state, loading: false, journals: action.journals, error: null};
    case types.FETCH_JOURNALS_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;