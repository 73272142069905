import * as types from '../actionTypes';

const initialState = {
  asideCategories: [],
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ASIDE_CATEGORIES_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_ASIDE_CATEGORIES_SUCCESS:
      return {...state, loading: false, asideCategories: action.asideCategories, error: null};
    case types.FETCH_ASIDE_CATEGORIES_FAILURE:
      return {...state, loading: false, error: action.error};
    default:
      return {...state};
  }
};

export default reducer;
