import axios from '../../axios';
import * as types from '../actionTypes';

export const changeLanguage = language => ({type: types.CHANGE_LANGUAGE, language});

const loginRequest = () => ({type: types.LOGIN_REQUEST});
const loginSuccess = (data, token) => ({type: types.LOGIN_SUCCESS, data, token});
const loginFailure = error => ({type: types.LOGIN_FAILURE, error});

export const login = (data, onResolve, onReject) => {
  return dispatch => {
    dispatch(loginRequest());
    return axios.get('/sanctum/csrf-cookie').then(
      () => {
        return axios.post('/api/login', data).then(
          response => {
            if (onResolve) onResolve();
            dispatch(loginSuccess(response.data, response.data.token || ''));
          },
          error => {
            if (onReject) onReject();
            dispatch(loginFailure(error.response.data));
          }
        )
      },
      () => {}
    )
  }
};

const logoutRequest = () => ({type: types.LOGOUT_REQUEST});
const logoutSuccess = () => ({type: types.LOGOUT_SUCCESS});
const logoutFailure = error => ({type: types.LOGOUT_FAILURE, error});

export const logout = (onResolve, onReject) => {
  return dispatch => {
    dispatch(logoutRequest());
    return axios.get('/api/private/logout').then(
      () => {
        if (onResolve) onResolve();
        dispatch(logoutSuccess());
      },
      error => {
        if (onReject) onReject();
        dispatch(logoutFailure(error));
      }
    )
  }
};

const refreshTokenRequest = () => ({type: types.REFRESH_TOKEN_REQUEST});
const refreshTokenSuccess = (data, token) => ({type: types.REFRESH_TOKEN_SUCCESS, data, token});
const refreshTokenFailure = () => ({type: types.REFRESH_TOKEN_FAILURE});

export const refreshToken = () => {
  return dispatch => {
    dispatch(refreshTokenRequest());
    return axios.get('/accounts/refresh').then(
      response => {
        dispatch(refreshTokenSuccess(response.data, response.data.token || ''));
      },
      error => {
        dispatch(refreshTokenFailure(error));
      }
    )
  }
};
