import * as types from '../actionTypes';

const initialState = {
  vote: {},
  voted: {},
  practiceVotes: {},
  practiceVotesLoading: false,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.ADD_VOTE_REQUEST:
      return {...state, loading: true, error: null};
    case types.ADD_VOTE_SUCCESS:
      return {...state, loading: false, error: null};
    case types.ADD_VOTE_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.CHECK_VOTED_REQUEST:
      return {...state, loading: true, error: null};
    case types.CHECK_VOTED_SUCCESS:
      return {...state, loading: false, voted: action.voted, error: null};
    case types.CHECK_VOTED_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.FETCH_PRACTICE_VOTES_REQUEST:
      return {...state, practiceVotesLoading: true, error: null};
    case types.FETCH_PRACTICE_VOTES_SUCCESS:
      const practiceVotes = {...state.practiceVotes};
      if (action.practiceVotes.length) {
        practiceVotes[action.practiceVotes[0].practice_id] = action.practiceVotes.length;
      }
      return {...state, practiceVotesLoading: false, practiceVotes, error: null};
    case types.FETCH_PRACTICE_VOTES_FAILURE:
      return {...state, practiceVotesLoading: false, error: action.error};
    case types.FETCH_PRACTICE_VOTES_DEFAULT:
      return {...state, practiceVotesLoading: false, error: null, practiceVotes: {}};

    default:
      return {...state};
  }
};

export default reducer;