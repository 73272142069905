import * as types from '../actionTypes';

const initialState = {
  forums: [],
  forum: {},
  status: null,
  loading: false,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.FETCH_FORUMS_REQUEST:
      return {...state, loading: true, error: null};
    case types.FETCH_FORUMS_SUCCESS:
      return {...state, loading: false, forums: action.forums, error: null};
    case types.FETCH_FORUMS_FAILURE:
      return {...state, loading: false, error: action.error};

    case types.CREATE_FORUM_REQUEST:
      return {...state, loading: true, status: null, error: null};
    case types.CREATE_FORUM_SUCCESS:
      return {...state, loading: false, forum: action.forum, status: action.status, error: null};
    case types.CREATE_FORUM_FAILURE:
      return {...state, loading: false, status: null, error: action.error};

    default:
      return {...state};
  }
};

export default reducer;